import TrackingEvent from "../TrackingEvent.interface";
import BaseProvider from "./BaseProvider";
import {getPageType} from "libs/Tracking/helpers";

type ga4Event = {
  type: string,
  params: {
    page_type?: string,
    coupon_id?: string,
    coupon_title?:string,
    shop_id?: string,
    shop_name?: string,
    shop_id_landing?: string,
    coupon_position?: string,
    coupon_element?: string,
    coupon_area?:string;
    goal_type?: string,
    anchor?: string,
    link_type?: string
  }
}

class Ga4Tracking extends BaseProvider {
  private pageType = "null";

  constructor() {
    super();

    if (this.isLoaded()) {
      this.pageType = getPageType();
      const shopId = window.eventLayer[0].shop_id || "null";
      this.onPageShow(shopId);
    }
  }

  protected prepareEvent(eventData: TrackingEvent): ga4Event | { type: null } {
    const readyEvent: ga4Event = {
      type: eventData.type,
      params: {
        page_type: this.pageType,
        coupon_id: eventData.coupon_id,
        coupon_title: eventData.coupon_title,
        shop_id: eventData.shop_id,
        shop_name: eventData.shop_name,
        shop_id_landing: eventData.shop_id_landing,
        coupon_position: eventData.coupon_position,
        coupon_element: eventData.coupon_element,
        ...(isLinkDataRequired()) && {
          coupon_area: eventData.coupon_area,
          anchor: eventData.anchor,
          link_type: eventData.link_type
        },
        ...(eventData.type === 'clickout') && {goal_type: 'coupon_clickout'}
      }
    }

    if (isValidEventType()) {
      return readyEvent;
    } else {
      return {type: null}
    }

    function isValidEventType() {
      return [
        'clickout',
        'non-clickout',
        'click',
        'newsletter',
        'earthMark',
        'donation'
      ].includes(eventData.type);
    }

    function isLinkDataRequired() {
      return eventData.type === 'click' || eventData.type === 'earthMark' || eventData.type === 'donation';
    }
  }

  protected onPageShow(shopId:string){
    window.gtag('event', 'page_view', {
      shop_id: shopId
    });
  }

  protected isLoaded(): boolean {
    return typeof window.gtag !== 'undefined';
  }

  sendProviderEvent(Event: TrackingEvent) {
    const ga4Event = this.prepareEvent(Event);

    if (this.isLoaded() && ga4Event.type !== null) {
      window.gtag('event', ga4Event.type, {
        ...ga4Event.params,
      })
    }
  }
}

const ga4Tracker = new Ga4Tracking();

export default ga4Tracker;
