import log from "../Utils/log";
import debounce from 'lodash/debounce';
import {toInteger} from "lodash";

export default (function() {

  const GAP = 0;
  const slideTime = 5000;

  function run() {
    document.querySelectorAll('[data-widget="premiumSlider"]').forEach(widget => {
      if (!widget) {
        return;
      }

      log('PremiumSlider:init');

      let slideWidth, autoplay, timeout, updateElementsTimer;
      const container = widget.querySelector('[data-container]');
      const arrowPrev = widget.querySelector('[data-arrow-prev]');
      const arrowNext = widget.querySelector('[data-arrow-next]');
      const indicators = widget.querySelectorAll('[data-indicator-dot]');
      const activeIndicatorClass = 'premium-slider__indicator-item--active';
      const slides = widget.querySelectorAll('[data-slide-index]');
      const curatorsList = widget.querySelector(".premium-slider__curators");
      const curators = widget.querySelectorAll("[data-curator-id]");

      if (!slides.length) {
        return;
      }

      pauseAutoPlay()

      const slideIndex = widget.querySelector('[data-slide-index]');
      const resizeObserver = new ResizeObserver(debounce(() => {
        slideWidth = slideIndex.clientWidth;
      }, 300));
      const slideObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const slideIndex = entry.target.dataset.slideIndex;
            updateElements(slideIndex);
          }
        });
      }, {root: container, threshold: 0.3});
      slides.forEach((slide) => {
        slideObserver.observe(slide);
      });

      resizeObserver.observe(container);

      arrowPrev.addEventListener('click', scrollToPrevSlide);
      arrowNext.addEventListener('click', scrollToNextSlide);
      indicators.forEach((dot, index) => {
        dot.addEventListener('click', () => {
          handlerIndication(index);
        });

        dot.addEventListener("mouseenter", pauseAutoPlay);
      });

      container.addEventListener("touchmove", pauseAutoPlay, { passive: true });
      arrowNext.addEventListener("mouseenter", pauseAutoPlay);
      arrowPrev.addEventListener("mouseenter", pauseAutoPlay);

      function scrollToPrevSlide() {
        let maxScrollLeft = container.scrollWidth - slideWidth;
        let value = container.scrollLeft - slideWidth - GAP;
        value = value >= 0 ? value : maxScrollLeft;
        container.scrollLeft = value;
      }

      function scrollToNextSlide() {
        let maxScrollLeft = container.scrollWidth - slideWidth;
        let value = container.scrollLeft + slideWidth + GAP;
        value = value <= maxScrollLeft ? value : 0;
        container.scrollLeft = value;
      }

      function changeActiveDot(index) {
        const activeDot = widget.querySelector(`.${activeIndicatorClass}`);
        if (activeDot) {
          activeDot.parentElement.dataset.activeItemIndex = (parseInt(index) + 1).toString();
          activeDot.classList.remove(activeIndicatorClass);
        }

        widget.querySelectorAll('[data-indicator-dot]')[index]?.classList.add(activeIndicatorClass);
      }

      function manageActiveArrows(index) {
        const lastSlideIndex = slides.length - 1;
        const indexAsNumber = toInteger(index);

        switch (indexAsNumber) {
          case 0:
            arrowNext.classList.add("active");
            arrowPrev.classList.remove("active");
            break;
          case lastSlideIndex:
            arrowNext.classList.remove("active");
            arrowPrev.classList.add("active");
            break;
          default:
            arrowNext.classList.add("active");
            arrowPrev.classList.add("active");
        }
      }

      function showCurator(index) {
        if (!curators || !curatorsList) {
          return false;
        }

        const curatorId = slides[index].dataset.targetCuratorId;
        const curator = widget.querySelector(`[data-curator-id="${curatorId}"]`);

        curators.forEach(el => {
          el.classList.remove(`${el.classList[0]}--active`);
        });

        if (curatorId && curator) {
          curator.classList.add(`${curator.classList[0]}--active`);
          curatorsList.classList.add(`${curatorsList.classList[0]}--active`);
        } else {
          curatorsList.classList.remove(`${curatorsList.classList[0]}--active`);
        }
      }

      function handlerIndication(index) {
        container.scrollLeft = index * (slideWidth + GAP);
        changeActiveDot(index);
        showCurator(index);
        manageActiveArrows(index);
      }

      function enableAutoPlay() {
        autoplay = setInterval(() => scrollToNextSlide(), slideTime);
      }

      function pauseAutoPlay() {
        clearInterval(autoplay);
        clearTimeout(timeout);
        timeout = setTimeout(enableAutoPlay, slideTime * 2);
      }

      function updateElements(index) {
        clearTimeout(updateElementsTimer);
        updateElementsTimer = setTimeout(() => {
          changeActiveDot(index);
          showCurator(index);
          manageActiveArrows(index);
        }, 200);
      }
    });
  }

  return {
    run: run
  };

})();
