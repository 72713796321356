import log from "../Utils/log";
import debounce from 'lodash/debounce';

export default (function() {

  const GAP = 0;
  let container, slideWidth, slides, activeIndicatorClass, timer, arrowPrev, arrowNext, indicators;

  function run() {
    let widget = document.querySelector('[data-widget="premiumCampaignSlider"]');
    if (!widget) {
      return;
    }

    slides = widget.querySelectorAll('[data-slide-index]');
    if (!slides.length) {
      return;
    }

    log("premiumCampaignSlider:init");

    container = widget.querySelector('[data-container]');
    arrowPrev = widget.querySelector('[data-arrow-prev]');
    arrowNext = widget.querySelector('[data-arrow-next]');
    indicators = widget.querySelectorAll('[data-indicator-dot]');
    activeIndicatorClass = 'premium-campaign-slider__indicator-item--active';

    const slideIndex = widget.querySelector('[data-slide-index]');
    const resizeObserver = new ResizeObserver(debounce(() => {
      slideWidth = slideIndex.clientWidth;
    }, 300));

    resizeObserver.observe(container);

    container.addEventListener("mouseenter", stopAutoPlay);
    container.addEventListener("mouseleave", startAutoPlay);

    arrowPrev.addEventListener('click', scrollToPrevSlide);
    arrowNext.addEventListener('click', scrollToNextSlide);
    indicators.forEach((dot, index) => {
      dot.addEventListener('click', () => {
        handlerIndication(index);
      });

      dot.addEventListener("mouseenter", () => {
        clearInterval(autoplay);
      });
    });

    const autoplay = setInterval(() => scrollToNextSlide(), 5000);
    container.addEventListener("touchmove", () => clearInterval(autoplay), { passive: true });

    const slideObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          let slideIndex = entry.target.dataset.slideIndex;
          widget.querySelector("." + activeIndicatorClass).classList.remove(activeIndicatorClass);
          widget.querySelectorAll('[data-indicator-dot]')[slideIndex].classList.add(activeIndicatorClass);
        }
      });
    }, {root: container, threshold: .1});
    slides.forEach((slide) => {
      slideObserver.observe(slide);
    });

    startAutoPlay();

    function scrollToPrevSlide() {
      stopAutoPlay();
      let maxScrollLeft = container.scrollWidth - slideWidth;
      let value = container.scrollLeft - slideWidth - GAP;
      value = value >= 0 ? value : maxScrollLeft;
      container.scrollLeft = value;
    }

    function scrollToNextSlide() {
      stopAutoPlay();
      let maxScrollLeft = container.scrollWidth - slideWidth;
      let value = container.scrollLeft + slideWidth + GAP;
      value = value <= maxScrollLeft ? value : 0;
      container.scrollLeft = value;
    }


    function startAutoPlay() {
      timer = setInterval(() => scrollToNextSlide(), 5000);
    }

    function stopAutoPlay() {
      clearInterval(timer);
    }
  }

  function handlerIndication(index) {
    container.scrollLeft = index * (slideWidth + GAP);
  }

  return {
    run: run
  };

})();
